html,
body,
#root {
    font-family: 'Maven Pro', sans-serif;
    background: rgb(10, 10, 10);
    position: static;
    -moz-overflow: hidden;
    -webkit-overflow: hidden;
    -ms-overflow: hidden;
    -o-overflow: hidden;
    overflow: hidden;
    color: #c2c2c2;
    font-size: 15px;
    letter-spacing: .25px;
}

input,
textarea {
    font-family: 'Maven Pro', sans-serif;
}

:root {
    --bg-primary: #091422;
    --bg-head-primary: #122231;
    --bg-portal-primary: #1b2633;
    --bg-body-primary: #051320;
    --bg-secondary: rgb(15, 20, 24);
    --text-primary: #D5D8DC;
    --text-secondary: rgb(15, 20, 24);
    --hover-bg-primary: #2C3E50;
    --hover-bg-secondary: rgb(15, 20, 24);
    --modal-bg : #c7c7c7;
    --bg-btn-reg-primary: #1D8348;
    --bg-btn-reg-primary-hover: #239B56;
    --bg-btn-reg-primary-active : #2ECC71;
    --btn-reg-text-color-primary :#0A2C19;
    --bg-btn-act-primary: #F1C40F;
    --bg-btn-act-primary-hover: #F4D03F;
    --bg-btn-act-primary-active : #F7DC6F;
    --btn-act-text-color-primary : #643D1B;
    --scroll-bar-color: #22303d;
    --scroll-bar-bg: transparent;
    --scroll-ancho: 8px;
    --swal2-info-text-color : #9e9e9e;
    --swal2-title-text-color : #c9c9c9;
    --swal2-bg : #040b11;
    --font-family : 'Maven Pro', sans-serif;
}
#root {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-overflow: hidden;
    -o-overflow: hidden;
    overflow: hidden;
}

h2 {
    color: #EAECEE;
}

.swal2-popup {
    background: var(--swal2-bg);
}

.all_container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    align-items: center;
    background-color: var(--bg-body-primary);
}

.container {
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    display: flex;
    height: 98%;
    width: 98%;
    z-index: 0;
    background-color: var(--bg-body-primary) ;
}

.main-left{
    position: absolute;
    display: flex;
    height: calc(100% - 10px);
    width: 20%;
    font-weight: 500;
    font-family: 'Maven Pro', sans-serif;
    left: 0;
    transition: all 500ms ease-in-out 100ms;
}

.main-right{
    position: absolute;
    display: flex;
    height: calc(100%);
    width: calc(80%);
    background-color: var(--bg-body-primary);
    font-weight: 500;
    right: 0;
    transition: all 500ms ease-in-out 100ms;
}

.main-right-container{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--bg-body-primary);
    display: block;
}

.div-menu{
    position: absolute;
    width: 53px;
    height: 65px;
    z-index: 99999;
    left: 0;
    background-color: var(--bg-primary);
    padding-left: 5px;
    
}

.show-main-left-false{
    position: absolute;
    display: flex;
    height: calc(100% - 35px);
    width: 20%;
    background-color: var(--bg-head-primary);
    font-weight: 500;
    font-family: 'Maven Pro', sans-serif;
    transition: all 500ms ease-in-out 100ms;
    left: -20%;
}

.show-main-right-complete{
    position: absolute;
    display: flex;
    height: calc(100%);
    width: calc(100% - 58px);
    background-color: var(--bg-body-primary);
    font-weight: 500;
    right: 0;
    transition: all 500ms ease-in-out 100ms;
}

.login {
    width: auto;
    max-width: 720px;
    height: 520px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    background: rgb(219, 242, 255);
    border-radius: 10px;
}

.logo-server{
    width: 55%;
    height: 520px;
    display: grid;
    align-content: center;
    justify-items: center;
    font-size: 48px;
    color: var(--bg-body-primary);
    font-weight: bolder;
}

.login-form {
    width: calc(45% - 20px);
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    background-color: rgb(219, 242, 255);
    border-radius: 10px;
}
.login-tipos{
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.login-tipos-iniciar{
    float: left;
    width: 100%;
    height: calc(85% - 105px);
    padding-top: 105px;
}

.login-footer{
    width: 100%;
    height: 15%;
    background-color: rgb(219, 242, 255);
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.login-options{
    width: 200px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.form-login{
    margin-top: 75px;
}

.menu-ico{
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
}

.sidebar-head{
    background-color: var(--bg-head-primary);
    height: 65px;
}
.sidebar-content{
    background-color: var(--bg-body-primary);
}
.sidebar-footer{
    background-color: var(--bg-head-primary);
}

.main-right-portal{
    position: relative;
    display: block;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    margin-bottom: 10px;
    font-weight: 500;
    float: left;
    padding: 15px 15px;
}
.portal-titulo{
    background: var(--bg-portal-primary);
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    border-radius: 10px;
}

.portal-c-c-p{
    background: var(--bg-portal-primary);
    height: calc(250px - 15px);
    width: calc(33.33% - 25px);
    display: block;
    justify-content: center;
    align-items: center;
    float: left;
    margin-top: 15px;
    padding: 7.5px 7.5px;
    border-radius: 10px;
}

.sub-portal-titulo{
    background: var(--bg-primary);
    height: 70px;
    width: calc(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    border-radius: 10px;
    margin-top: 5px;
    font-size: 1.32rem;
}

.portal-c-c-p-contactos{
    background: var(--bg-primary);
    height: 145px;
    width: calc(33.33% - 6.7px);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    border-radius: 10px;
    font-size:.85vw;
    
}

.effect{
    animation-name: mostrar-30;
    animation-duration: .9s;
}

.portal-c-c-p-cuentas{
    background: var(--bg-primary);
    height: 145px;
    width: calc(33.33% - 6.7px);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    font-size:.85vw;
}
.portal-c-c-p-perfiles{
    background: var(--bg-primary);
    height: 145px;
    width: calc(33.33% - 6.7px);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    font-size:.85vw;
}
.portal-tops{
    background: var(--bg-portal-primary);
    height: calc(250px - 15px);
    width: calc(33.33% - 25px);
    display: block;
    justify-content: center;
    align-items: center;
    float: left;
    margin-top: 15px;
    padding: 7.5px 7.5px;
    border-radius: 10px;
    margin-left: 15px;
}


.portal-ganancias{
    background: var(--bg-portal-primary);
    height: calc(250px - 15px);
    width: calc(33.33% - 25px);
    display: block;
    justify-content: center;
    align-items: center;
    float: left;
    margin-top: 15px;
    padding: 7.5px 7.5px;
    border-radius: 10px;
    margin-left: 15px;
}

.portal-ganancias-icon{
    background: var(--bg-primary);
    height: 145px;
    width: calc(50% - 5px);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    border-radius: 10px;
    font-size:.95vw;
}

.portal-ganancias-money{
    background: var(--bg-primary);
    height: 145px;
    width: calc(50% - 5px);
    display: grid;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    font-size:1.5vw;
}

.portal-alerta-sistema{
    background: var(--bg-portal-primary);
    height: calc(100% - 401px);
    width: calc(50% - 22.5px);
    display: block;
    justify-content: center;
    align-items: center;
    float: left;
    margin-top: 15px;
    padding: 7.5px 7.5px;
    border-radius: 10px;
}

.portal-alerta-servidor{
    background: var(--bg-portal-primary);
    height: calc(100% - 401px);
    width: calc(50% - 22.5px);
    display: block;
    justify-content: center;
    align-items: center;
    float: left;
    margin-top: 15px;
    padding: 7.5px 7.5px;
    border-radius: 10px;
    margin-left: 15px;
}

.alert-contain{
    background: var(--bg-primary);
    height: calc(100% - 105px);
    width: calc(100% - 20px);
    display: block;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-content: center;
    align-self: center;
    justify-items: center;
    float: left;
    margin-top: 10px;
    padding: 10px 10px;
    overflow-y: auto;
}

.head-menu{
    width: calc(100%);
    background-color: var(--bg-head-primary);
    height: 65px;
    align-items: center;
    align-content: center;
    align-self: center;
    position: relative;
    display: block;
}

.empresa-title-name{
  width: calc(100% - 130px);
  float: left;
  height: 65px;
  justify-items: center;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 70px;
  justify-items: center;
  align-items: center;
}
.logout{
    width: 60px;
    float: right;
    height: 65px;
    justify-items: center;
    align-items: center;
    display: flex;
}

#menu {
    padding: 0;
    margin: 0;
    border: 0;
}
 
#menu ul, li {
    list-style: none;
    margin: 0;
    padding: 0; 
}
 
#menu ul {
    position: relative;
    z-index: 597;
    float: left; 
}
 
#menu ul li {
    float: left;
    min-height: 1px;
    line-height: 1em;
    vertical-align: middle;
}
 
#menu ul li.hover,
#menu ul li:hover {
    position: relative;
    z-index: 599;
    cursor: default; 
}
 
#menu ul ul {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 598;
    width: 100%; 
}
 
#menu ul ul li {
    float: none; 
}
 
#menu ul li:hover > ul {
    visibility: visible;
}
 
#menu ul ul {
    top: 0;
    left: 100%; 
}
 
#menu ul li {
    float: none; 
}
 
#menu {
    width: 100%;
    position: absolute;
}

#menu-hide ul ul {
    position: absolute;
    left: 300px;
}
 
#menu span, #menu a {
    display: inline-block;
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    text-decoration: none; 
}
 
#menu:after, #menu ul:after {
    content: '';
    display: block;
    clear: both;
}
 
#menu ul, #menu li {
    width: calc(100% - 14px); 
}

#menu li {
    background: var(--bg-primary);
    padding-left: 28px;
}
#menu li:hover {
    background: var(--hover-bg-primary); 
}
#menu a {
    color: var(--text-primary);
    line-height: 160%;
    padding: 16px 0px 16px 0px;
    width: 100%;
    border-bottom: 1px solid var(--bg-head-primary);
}
#menu ul ul li {
    background: var(--bg-primary);
}
#menu ul ul li:hover {
    background: var(--hover-bg-primary); 
}
#menu ul ul li:hover a {
    color: var(--text-primary); 
}
#menu ul ul li ul li {
    background: var(--bg-primary); 
}
#menu ul ul li ul li:hover {
    background: var(--hover-bg-primary); 
}
#menu .has-sub {
    position: relative;
    font-family: 'Maven Pro', sans-serif;
}

.has-sub .has-sub2 {
    position: relative;
    font-family: 'Maven Pro', sans-serif;
}

#search_contact_contacts{
    font-family: 'Maven Pro', sans-serif;
}
 
.has-sub .has-sub:after, .has-sub .has-sub > ul > .has-sub:hover:after {
    content: '';
    display: block;
    width: 10px;
    height: 9px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
}


#menu-hide {
    padding: 0;
    margin: 0;
    border: 0;
}
 
#menu-hide ul, li {
    list-style: none;
    margin: 0;
    padding: 0; 
}
 
#menu-hide ul {
    position: relative;
    z-index: 597;
    float: left; 
}
 
#menu-hide ul li {
    float: left;
    min-height: 1px;
    line-height: 1em;
    vertical-align: middle;
}
 
#menu-hide ul li.hover,
#menu-hide ul li:hover {
    position: relative;
    z-index: 599;
    cursor: default; 
}
 
#menu-hide ul ul {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 598;
    width: 100%; 
}
 
#menu-hide ul ul li {
    float: none; 
}
 
#menu-hide ul li:hover > ul {
    visibility: visible;
}
 
#menu-hide ul ul {
    top: 0;
    left: 100%; 
}
 
#menu-hide ul li {
    float: none; 
}
 
#menu-hide {
    width: 68px;
    position: absolute;
    left: calc(100% - 10px);
}

#menu-hide-hide ul ul {
    position: absolute;
    left: 300px;
}
 
#menu-hide span, #menu-hide a {
    display: inline-block;
    font-family: 'Maven Pro', sans-serif;
    font-size: 14px;
    text-decoration: none; 
}
 
#menu-hide:after, #menu-hide ul:after {
    content: '';
    display: block;
    clear: both;
}
 
#menu-hide ul, #menu-hide li {
    width: calc(100% - 14px); 
}

#menu-hide li {
    background: var(--bg-primary);
    padding-left: 28px;
}
#menu-hide li:hover {
    background: var(--hover-bg-primary); 
}
#menu-hide a {
    color: var(--text-primary);
    line-height: 160%;
    padding: 16px 0px 16px 0px;
    width: 100%;
    border-bottom: 1px solid var(--bg-head-primary);
}
#menu-hide ul ul li {
    background: var(--bg-primary);
}
#menu-hide ul ul li:hover {
    background: var(--hover-bg-primary); 
}
#menu-hide ul ul li:hover a {
    color: var(--text-primary); 
}
#menu-hide ul ul li ul li {
    background: var(--bg-primary); 
}
#menu-hide ul ul li ul li:hover {
    background: var(--hover-bg-primary); 
}
#menu-hide .has-sub {
    position: relative;
    font-family: 'Maven Pro', sans-serif;
}

#menu .has-sub2:after, #menu .has-sub2 > ul > .has-sub2:hover:after {
    content: '';
    display: block;
    width: 10px;
    height: 9px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
}

#menu-hide .has-sub2:after, #menu-hide .has-sub2 > ul > .has-sub2:hover:after {
    content: '';
    display: block;
    width: 10px;
    height: 9px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
}
 
#menu .fa-angle-right {
    float: right;
}

#menu-hide .fa-angle-right {
    float: right;
}

.search_contact
{
    height: 55px;
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    position: relative;
}

.search_cuentas_perfiles
{
    height: 55px;
    margin-top: 20px;
    margin-bottom: 15px;
    width: calc(100% - 15px);
    position: relative;
    margin-left: 15px;
}

.title-pages{
    width: 100%;
    height: 65px;
    background: var(--bg-head-primary);
    display: flex;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-items: center;
    align-content: center;
    position: relative;
    border-left-style: solid;
    border-left-color: var(--bg-body-primary);
    float: left;
}
.main-right-info-container{
    display: block;
    width: 100%;
    height: calc(100% - 65px);
    background: var(--bg-body-primary);
    position: relative;
    float: left;
}

.main-right-info-container-cuentas{
    display: block;
    width: 100%;
    height: calc(100% - 65px);
    background: var(--bg-body-primary);
    position: relative;
    float: left;
}

.title-pages-modal{
    width: 100%;
    height: 65px;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
}

.icon-right{
    float: left;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_title_pages_modal {
    width: calc(100% - 130px);
    font-size:24.5px;
    height: 65px;
    float: left;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}

.title_ico_pages_modal {
    float: left;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
}

.title-pages-modal-combo{
    width: 100%;
    height: 65px;
    background: var(--bg-head-primary);
    display: block;
    position: relative;
}

.title-pages-modal-login{
    width: 100%;
    height: 65px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    color: var(--bg-body-primary);
}

.div_title_pages_login {
    width: calc(100% - 75px);
    font-size:28.5px;
    height: 65px;
    float: left;
    position: relative;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
}

.title_ico_pages {
    float: left;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
}

.btn-atras {
    width: 40px;
    height: 40px;
    outline: none;
    outline: transparent;
    border: none;
    border-color: transparent;
    border-radius: 100%;
    background: none;
    cursor: pointer;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    background: var(--bg-body-primary);
}

.btn-atras:hover {
    width: 40px;
    height: 40px;
    outline: none;
    outline: transparent;
    background: var(--hover-bg-primary);
    border: none;
    border-color: transparent;
    border-radius: 100%;
}

.btn-atras:active {
    width: 40px;
    height: 40px;
    background: var(--hover-bg-primary);
    outline: none;
    outline: transparent;
    border: none;
    border-color: transparent;
    border-radius: 100%;
}

.btn-atras:focus {
    width: 40px;
    height: 40px;
    outline: none;
    background: var(--hover-bg-primary);
    outline: transparent;
    border: none;
    border-color: transparent;
    border-radius: 100%;
    transition: all 0.5s ease;
}

.div_title_pages {
    width: calc(100% - 65px);
    font-size:30.5px;
    height: 65px;
    float: left;
    position: relative;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    align-content: center;
    justify-self: center;
    justify-items: center;
}

.bar_buttons {
    display: flex;
    float: right;
    height: 100%;
    align-items: center;
    margin-right: 10px;
}

.btn-config {
    width: 40px;
    height: 40px;
    outline: none;
    outline: transparent;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 0px;
    padding-top: 5px;
}

.btn-config:hover {
    width: 40px;
    height: 40px;
    outline: none;
    outline: transparent;
    background: var(--hover-bg-primary);
    border: none;
    border-color: transparent;
    border-radius: 100%;
    margin-top: 0px;
    padding-top: 5px;
}

.btn-config-active {
    width: 40px;
    height: 40px;
    background: rgb(22, 25, 29);
    outline: none;
    outline: transparent;
    border: none;
    border-color: transparent;
    border-radius: 100%;
    margin-top: 0px;
    padding-top: 5px;
}

.modal{
    background-color: rgba(0, 0, 0, 0.877);
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    transition: all 1s;
    z-index: 999999;
}

.modal-contacto{
    background-color: rgba(0, 0, 0, 0.877);
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    transition: all 1s;
    z-index: 99999999;
}

.modal-contenido{
    background-color:var(--bg-head-primary);
    width:320px;
    padding: 10px 20px;
    position: relative;
    z-index: 999999;
    margin-left: auto;
    margin-right: auto;
}

.modal-contenido-plataformas{
    background-color:var(--bg-head-primary);
    width:1024px;
    z-index: 999999;
    margin-left: auto;
    margin-right: auto;
    height: auto;
}

.modal-contenido-perfiles{
    background-color:var(--bg-head-primary);
    width:360px;
    padding: 10px 20px;
    position: relative;
    z-index: 999999;
    margin-left: auto;
    margin-right: auto;
}

.div-info{
    color: #ffed8a;
    width: calc(100% - 15px);
    height: 37px;
    background: #10212D;
    padding-top: 18px;
    padding-left: 15px;
    margin-top: 0px;
    margin-bottom: 20px;
}

#miModal:target{
    opacity:1;
    pointer-events:auto;
  }
.swal-modal {
    background: var(--alert-bg-color)
}

.swal2-html-container{
    color: var(--swal2-info-text-color);
}

.swal2-title{
    color: var(--swal2-title-text-color);
}

.form-modal{
    width: calc(100% - 20px);
    background-color: var(--bg-body-primary);
    padding: 10px;
}

.form-modal-plataformas{

    width: calc(100% - 30px);
    background-color: var(--bg-head-primary);
    padding: 15px 15px;
    display: block;
    height: auto;
    position: relative;
    float: left;
}

.form-input-container{
    width: 86%;
    padding: 5px 20px;
    display: grid;
}

.max-length{
    opacity: .8;
    font-size: 13px;
    padding-left: 12px;
    color : var(--text-primary);
    margin-right: 5px;
}

.form-input{
    background: transparent !important;
    border: 0;
    outline:none !important;
    outline-offset: 0;
    border-bottom: 1px solid #888 !important;
    height: 30px;
    font-size: 16.5px;
    color: var(--text-primary);
    padding-left: 5px;
}

.form-input-textarea{
    background: transparent !important;
    border: 0;
    outline:none !important;
    outline-offset: 0;
    border-bottom: 1px solid #888 !important;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    font-size: 16.5px;
    color: var(--text-primary);
    padding-left: 5px;
}

.fa-search {
    position: absolute;
    right: 0;
    margin-top: 25px;
    margin-right: 45px;
}

.icon-adorno{
    margin-top: 10px;
}

.icon-adorno-exp{
    margin-top: 10px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 7px;
    padding-bottom: 5px;
    margin-bottom: 7px;
    border-radius: 25px;
    color: var(--swal2-info-text-color);
}

.form-input-select{
    background: var(--bg-body-primary);
    border: 0;
    outline:none !important;
    outline-offset: 0;
    border-bottom: 1px solid #888 !important;
    height: 30px;
    font-size: 15.5px;
    color: var(--text-primary);
    padding: 5px 5px;
}

.btn-register
{
    width: 120px;
    height: 45px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    background-color: var(--bg-btn-reg-primary);
    outline: none !important;
    border: none;
    border-radius: 10px;
    color: var(--btn-reg-text-color-primary);
    font-weight: 500;
}

.btn-register:hover
{
    background-color: var(--bg-btn-reg-primary-hover);
}

.btn-register:active
{
    background-color: var(--bg-btn-reg-primary-active);
}

.btn-actualizar
{
    width: 120px;
    height: 45px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    background-color: var(--bg-btn-act-primary);
    outline: none !important;
    border: none;
    border-radius: 10px;
    color: var(--btn-act-text-color-primary);
    font-weight: 500;
}

.btn-actualizar:hover
{
    background-color: var(--bg-btn-act-primary-hover);
}

.btn-actualizar:active
{
    background-color: var(--bg-btn-act-primary-active);
}

.text-span{
    margin-top: 8px;
    margin-right: 9px;
    position: relative;
    float: right;
}

.text-span-2{
    margin-top: 8px;
    margin-left: 6px;
    position: absolute;
    float:inherit;
    display: block;
}

.container_info{
    background-color: var(--bg-head-primary);
    width: calc(97% - 10px);
    margin-left: auto;
    margin-right: auto;
    padding: 10px 10px;
    height: calc(100% - 208px);
    overflow-y: auto;
    overflow-x: hidden;
}

.container_info_contactos{
    background-color: var(--bg-head-primary);
    width: calc(97% - 10px);
    margin-left: auto;
    margin-right: auto;
    padding: 10px 10px;
    height: calc(100% - 113px);
    overflow-y: auto;
    overflow-x: hidden;
}

.item_container{
    background-color: var(--bg-body-primary);
    width: 100%;
    height: 65px;
    margin-bottom: 10px;
    border-bottom: 6px solid var(--bg-body-primary);
    border-top: 6px solid var(--bg-body-primary);
    animation-name: mostrar-30;
    animation-duration: .9s;
    position: relative;
    overflow: hidden;
}

.item-block{
    width: 100%;
    display: inline-block;
    height: 65px;
}

.item-block:hover{
    background: var(--bg-head-primary);
}

.item-block-cuentas{
    width: 100%;
    display: inline-block;
    height: 65px;
}
.item-block-cuentas:hover{
    background: var(--bg-head-primary);
}

.item_list_cuentas_2{
    font-size: 18px;
    width: 33.3%;
    display: block;
    position: relative;
    float: left;
}
.item_list_cuentas_3{
    font-size: 18px;
    width: 45%;
    display: block;
    position: relative;
    float: left;
}

.item_list_cuentas_4{
    font-size: 18px;
    width: 10%;
    display: block;
    position: relative;
    float: left;
}

.item_list-cuentas-correo{
    float: left;
    position: relative;
    width: 100%;
    height: 24.5px;
    padding-top: 8px;
    opacity: 1;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item_list-cuentas-key{
    float: left;
    position: relative;
    width: 100%;
    height: 24.5px;
    padding-top: 8px;
    opacity: .9;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item_list-cuentas-tipo{
    float: left;
    position: relative;
    width: 100%;
    height: 32.5px;
    opacity: .8;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.text-exp{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
}

.item_list-cuentas-exp{
    float: left;
    position: relative;
    width: 100%;
    height: 20.5px;
    padding-top: 8px;
    text-align: center;
    opacity: 1;
    color: #E74C3C;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item_list-cuentas-fechaexp{
    float: left;
    position: relative;
    width: 100%;
    height: 65px;
    text-align: center;
    opacity: 1;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(83, 240, 161);
}

.item_list-cuentas-fechaexp-caducado{
    float: left;
    position: relative;
    width: 100%;
    height: 65px;
    text-align: center;
    opacity: .9;
    font-size: 14px;
    font-weight: bold;
    color: #E74C3C;
    animation-duration: .5s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.item_list-cuentas-proximo{
    float: left;
    position: relative;
    width: 100%;
    height: 65px;
    text-align: center;
    opacity: .9;
    font-size: 14px;
    color: rgb(252, 169, 46);
    animation-duration: 1s;
    animation-name: slidein2;
    animation-iteration-count: infinite;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@keyframes slidein {
    from {
        text-shadow: 0px 0px 0px #ffffff;
    }
  
    to {
        text-shadow: 0px 0px 20px #ce1500;
    }
  }

  @keyframes slidein2 {
    from {
        text-shadow: 0px 0px 0px #ffffff;
    }
  
    to {
        text-shadow: 0px 0px 20px #ffa600;
    }
  }

.item_list-cuentas-created{
    float: left;
    position: relative;
    width: calc(100% - 20px);
    height: 24.5px;
    padding-top: 8px;
    text-align: right;
    padding-right: 20px;
    font-size: 13px;
    opacity: .9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
#Contacto{
    color: #c2c2c2;
    font-size: 17px;
    font-family: 'Maven Pro', sans-serif;
}
.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeMedium .MuiAutocomplete-clearIndicator .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
    color: #c2c2c2;
}

#correo{
    color: #c2c2c2;
    font-size: 17px;
    font-family: 'Maven Pro', sans-serif;
}

.item_list-cuentas-estadouso{
    float: left;
    position: relative;
    width: calc(100% - 20px);
    height: 32.5px;
    text-align: right;
    padding-right: 20px;
    opacity: 1;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.user-options{
    float: left;
    position: relative;
    width: calc(100% - 30px);
    height: 32.5px;
    padding-right: 20px;
    padding-left: 10px;
    opacity: 1;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.admin{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(255, 79, 77);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}
.superadmin{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(255, 222, 77);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}

.disabled{
    filter: contrast(0%);
}

.registrado{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(83, 240, 161);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}

.locked{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(255, 79, 77);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin-right: 8px;
}

.cuenta-full{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(255, 79, 77);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}

.cuenta-usado{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(252, 169, 46);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}

.tipo-perfil-combo{
    background-color: rgb(71, 0, 138);
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(234, 212, 255);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin-right: 7px;
}

.tipo-perfil-normal{
    background-color: rgb(0, 51, 109);
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(182, 216, 255);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin-right: 7px;
}

.cuenta-libre{
    background-color: #04192c;
    padding: 5px 7px;
    border-radius: 5px;
    float: right;
    color: rgb(83, 240, 161);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
}


.item_list-cuentas-estadouso-2{
    float: left;
    position: relative;
    width: calc(100% - 20px);
    height: 32.5px;
    text-align: right;
    padding-right: 20px;
    opacity: 1;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.item_list{
    height: 25px;
    padding-top: 10px;
    font-size: 18px;
    width: 72%;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.item_list_fecha{
    height: 25px;
    padding-top: 10px;
    font-size: 18px;
    width: calc(28% - 15px);
    float: left;
    text-align: right;
    font-size: 12px;
    opacity: .7;
    margin-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sub_item_list{
    height: 24px;
    padding-top: 6px;
    font-size: 13px;
    float: left;
    opacity: .7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.icon_right{
    width: 100px;
    height: 65px;
    display: flex;
    align-items: center;
}

.icon_right_keys{
    width: 60px;
    height: 65px;
    display: flex;
    align-items: center;
}

.icon_right_users{
    width: 155px;
    height: 65px;
    display: flex;
    align-items: center;
}

.item-left{
    display: block;
    float: left;
    width: calc(100% - 165px);
    animation-name: mostrar-30;
    animation-duration: 1s;
}

.item-left-keys{
    display: block;
    float: left;
    width: calc(100% - 125px);
    position: relative;
    animation-name: mostrar-30;
    animation-duration: 1s;
}

.item-left-cuentas{
    display: block;
    float: left;
    width: calc(100% - 165px);
    position: relative;
    animation-name: mostrar-30;
    animation-duration: 1s;
}

.item-left-users{
    display: block;
    float: left;
    width: calc(100% - 220px);
    position: relative;
    animation-name: mostrar-30;
    animation-duration: 1s;
}

.icon_left{
    float: left;
    height: 65px;
    width: 55px;
    align-items: center;
    display: flex;
    align-content: center;
    align-self: center;
    padding-left: 10px;
}

.preloader {
    border: 3px solid #0084a5;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation-name: girar;
    animation-duration: .5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-left: 20px;
  }
  @keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    width: var(--scroll-ancho);
    background-color: var(--scroll-bar-bg)
}

::-webkit-scrollbar-thumb {
    width: var(--scroll-ancho);
    background-color: var(--scroll-bar-color);
}

.sin_datos{
    background-color: var(--bg-body-primary);
    color: var(--text-primary);
    width: 350px;
    height: 65px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    border-radius: 15px;
}

.sin_datos_left{
    float: left;
    width: 62px;
    height: 65px;
    padding-left: 8px;
}

.sin_datos_right{
    float: left;
    height: 49px;
    width: calc(100% - 86px);
    padding: 8px 8px;
}

.contador-div{
    opacity: .6;
    font-size: 20px;
    margin-left: 20px;
    background-color: var(--bg-body-primary);
    width: 45px;
    height: 45px;
    border-radius: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cuentas-container-div{
    width: 100%;
    display: flex;
    position: relative;
    height: 95px;
    align-items: center;
    justify-content: center;
}
.cuentas-sub-container-div{
    width: auto;
    position: relative;
    display: block;
    height: 65px;
}

.cuentas-container-div-item{
    float: left;
    width: 70px;
    height: 70px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--bg-head-primary);
}

.cuentas-container-div-item-combo-2{
    float: left;
    width: 60px;
    height: 60px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    margin-right: 5px;
    margin-left: 13px;
    border-radius: 50%;
    margin-top: 10px;
    animation-duration: .5s;
    animation-name: efecto1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--bg-body-primary);
}

@keyframes efecto {
    from {
        transform: translateX(350%);
      }
      to {
        transform: translateX(0%);
      }
  }

  @keyframes efecto1 {
    from {
        transform: translateX(-350%);
      }
      to {
        transform: translateX(0%);
      }
  }

.img-icon{
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.cuentas-container-div-item-combo:hover{
    filter: brightness(1.5);
}

.cuentas-container-div-item-combo-2:hover{
    filter: brightness(1.5);
}

.cuentas-container-div-item:hover{
    filter: brightness(1.5);
}

.cuentas-container-div-item-hover{
    float: left;
    width: 70px;
    height: 70px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 7px var(--hover-bg-primary);
    filter: brightness(1.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--bg-head-primary);
}
.user-expiration{
    width:100%;
	height:80px;
	float:right;
	bottom:0px;
	position:relative;
    text-align: center;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 5px;
    color: var(--swal2-info-text-color);
    font-weight: 600;
    background: var(--bg-primary);
    display: flex;
    font-size: 13px;
    font-weight: 400;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

.menu{
    height: calc(100% - 80px);
}

.server-status{
    display: flex;
    font-size: 13px;
    font-weight: 400;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

.alert-info{
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    width: calc(100% - 40px);
    padding: 10px;
    margin: 10px;
    display: flex;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-weight: bolder;
}

@keyframes mostrar-30 {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    background-color: var(--bg-body-primary);
    color: var(--text-primary);
    font-size: 13px;
    font-family: var(--font-family);
    position: absolute;
    padding: 5px 8px;
    display: flex;
    right: 94%;
    top: 50%;
    white-space: nowrap;
    opacity: 0;
    border: 1px solid var(--bg-body-primary);
    z-index: 99999;
    visibility: hidden;
    border-radius: 10px;
    
}
[data-title] {
    position: relative;
}

.usuario-bloqueado{
    padding: 10px 10px;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.sub-usuario-bloquedo{
    width: 320px;
    height: 220px;
    display: grid;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    color: rgb(252, 47, 44);
    background: var(--bg-head-primary);
    border-radius: 25px;
}

.info-head{
    display: flex;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    font-size: 24px;
    font-family: var(--font-family);
}

.info-body{
    margin-top: 10px;
    padding: 10px;
    text-align: center;
    color: var(--text-primary);
    font-family: var(--font-family);
    font-size: 14px;
}


.usuario-expirado{
    padding: 10px 10px;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.sub-usuario-expirado{
    width: 320px;
    height: 220px;
    display: grid;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    color: rgb(252, 170, 63);
    background: var(--bg-head-primary);
    border-radius: 25px;
}

.alerta-arriba-derecha{
    width: 200px;
    height: 50px;
    position: fixed;
    top: 0;
    right: o;
}

.expiration-alert{
    padding: 3px 3px;
    width: 95px;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    display: grid;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
}

.sin-autoridad{
    padding: 10px 10px;
}

.version{
    font-size: 15px;
    opacity: .7;
    margin-left: 8px;
    margin-left: auto;
    margin-right: auto;
}

.current_combo{
    height: 230px;
    background: #10212D;
    margin-bottom: 10px;
    padding: 7px 7px;
    overflow: hidden;
}
.container-combo{
    display: flex;
}

.modal-contenido-combo{
    background-color:var(--bg-head-primary);
    width:380px;
    padding: 10px 20px;
    position: relative;
    z-index: 999999;
    margin-left: auto;
    margin-right: auto;
}

.current_plataforms{
    width: 87px;
    height: auto;
    padding: 7px 7px;
    display: grid;
    justify-items: center;
    align-content: center;
    margin-right: 20px;
    background: var(--bg-body-primary);
    overflow: hidden;
}

.img-icon-combo{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.cuentas-container-div-item-combo{
    float: left;
    width: 60px;
    height: 60px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    animation-duration: .5s;
    animation-name: efecto;
    margin-top: 7px;
    background: var(--bg-head-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--bg-head-primary);
}

.cuentas-container-div-item-combo-disabled{
    float: left;
    width: 60px;
    height: 60px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    margin-top: 7px;
    filter: contrast(70%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--bg-head-primary);
}

.form-modal-combo{
    width: calc(100%);
    background-color: var(--bg-body-primary);
    padding: 10px;
    float: left;
    position: relative;
}

.label-plataformas{
    position: absolute;
    margin-top: -8.5PX;
    margin-left: 10px;
    font-size: 13px;
}


.ver_preview {
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;
    cursor: pointer;
    border-radius: 100%;
    position: flex;
    margin-top: -210px;
}

.ver_preview_off {
    display: none;
}

.p_text_cambiar_foto {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 45px;
    width: 160px;
    font-size: 20px;
}

.p_text_cambiar_foto_group {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 45px;
    width: 160px;
    font-size: 17px;
}

.btn-next-div
{
    height:80px;
    position: absolute;
    margin-left: 45%;
    bottom: 0;
}

#image_perfil {
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;
    border-radius: 100%;
    cursor: pointer;
}

.image_perfil {
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;
    border-radius: 100%;
    cursor: pointer;
}

@keyframes mostrar-scale-0 {
    from {
      transform: scale(0.1);
    }
    to {
      transform: scale(1);
    }
  }

.image_perfil_2{
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 100%;
    cursor: pointer;
}

#fileimage {
    display: none;
}

.image-upload img {
    width: 30px;
    cursor: pointer;
}

.image_perfil:hover {
    opacity: 0.4;
}

#image_perfil:hover {
    opacity: 0.3;
}

.perfil{
    display: grid;
}

.btn-upfile-div
{
    height:60px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_image_perfil {
    width: 100%;
    align-content: center;
    align-items: center;
    height: 180px;
    animation-name: mostrar-scale-0;
    animation-duration: 1s;
}

.logo-image{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
}
.img-logo-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.off{
    display: none;
    visibility: hidden;
}

.item-plataformas{
    display: block;
    height: 175px;
    width: 210.9px;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--hover-bg-primary);
    float: left;
    margin-left: 10px;
    margin-top: 28px;
}

.container-plataformas{
    display: block;
    width: calc(100% - 20px);
    background: var(--bg-body-primary);
    float: left;
    padding: 10px 10px;
}